<template>
  <transition name="slide">
    <div class="subpage">
      <PageText>
        <template v-slot:title>{{ $route.meta.title }}</template>
        <template v-slot:subtitle v-if="$route.meta.subtitle">{{ $route.meta.subtitle }}</template>
        <template v-slot:text>
          <p class="form__error">{{ message }}</p>
          <p class="form__success" v-if="success">Dziękujemy za rejestrację.<br />Do zobaczenia!</p>
          <p class="form__success" v-if="limitPeople">
            Przepraszamy! <br />
            Limit osób został osiągnięty
          </p>
          <div class="form" v-if="success == false">
            <div v-if="errors.length > 0">
              <h2 class="form__header ">Błędy podczas wypełnienia formularza</h2>
              <p class="form__error">{{ errors.join(", ") }}</p>
            </div>
            <h2 class="form__header">Dane personalne</h2>
            <div class="form__row">
              <Input
                class="form__row--col-2"
                placeholder="Imię"
                :value="inputs.name"
                :error="validation.firstError('inputs.name')"
                @return="inputs.name = $event"
              />
              <Input
                class="form__row--col-2"
                placeholder="Nazwisko"
                :value="inputs.surname"
                :error="validation.firstError('inputs.surname')"
                @return="inputs.surname = $event"
              />
              <Select
                class="form__row--col-2"
                v-if="informations"
                label="Narodowość"
                :options="informations.nationality"
                default=""
                :getSelected="inputs.nationality"
                :multiple="false"
                @input="inputs.nationality = $event"
                :error="validation.firstError('inputs.nationality')"
              >
                <template v-slot:display="{selectedElementstoDisplay}">
                  <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
                    <div v-for="item in selectedElementstoDisplay" :key="item.id">
                      {{ informations.nationality.find((e) => e.id == item).value }}
                    </div>
                  </div>
                  <div class="select__display" v-else></div>
                </template>
                <template v-slot:option="{choose, activateSelected}">
                  <div
                    class="select__item"
                    :class="{
                      'select__item--active': activateSelected(parseInt(option.id)),
                    }"
                    v-for="(option, i) of informations.nationality"
                    :key="i"
                  >
                    <div @click="choose(option.id)" v-if="option">
                      {{ option.value }}
                    </div>
                  </div>
                </template>
              </Select>

              <Input
                v-if="inputs.nationality == 1"
                class="form__row--col-2"
                placeholder="PESEL"
                :value="inputs.pesel"
                :error="validation.firstError('inputs.pesel')"
                @return="(inputs.pesel = $event), getInfoFromPesel($event)"
              />
              <Input
                v-if="inputs.nationality == 2"
                class="form__row--col-2"
                placeholder="Numer paszportu"
                :value="inputs.passport"
                :error="validation.firstError('inputs.passport')"
                @return="inputs.passport = $event"
              />
              <Input
                v-if="inputs.nationality == 2"
                class="form__row--col-2"
                placeholder="Data urodzenia (dd-mm-rrrr)"
                :value="inputs.datebirth"
                :datemask="true"
                :error="validation.firstError('inputs.datebirth')"
                @return="(inputs.datebirth = $event), getInfoDateBirth($event)"
              />
            </div>
            <h2 class="form__header">Dane kontaktowe</h2>
            <div class="form__row">
              <Input
                class="form__row--col-2"
                placeholder="E-mail"
                :value="inputs.mail"
                :error="validation.firstError('inputs.mail')"
                @return="inputs.mail = $event"
              />
              <Input
                class="form__row--col-2"
                placeholder="Telefon"
                :value="inputs.phone"
                :error="validation.firstError('inputs.phone')"
                @return="inputs.phone = $event"
              />
            </div>
            <h2 class="form__header">Przydatne informacje</h2>
            <div class="form__row">
              <Select
                class="form__row--col-2"
                v-if="informations"
                label="Diety"
                :options="informations.diets"
                default=""
                :getSelected="inputs.diet_id"
                :multiple="false"
                @input="inputs.diet_id = $event"
              >
                <template v-slot:display="{selectedElementstoDisplay}">
                  <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
                    <div v-for="item in selectedElementstoDisplay" :key="item.id">
                      {{ informations.diets.find((e) => e.id == item).value }}
                    </div>
                  </div>
                  <div class="select__display" v-else></div>
                </template>
                <template v-slot:option="{choose, activateSelected}">
                  <div
                    class="select__item"
                    :class="{
                      'select__item--active': activateSelected(parseInt(option.id)),
                    }"
                    v-for="(option, i) of informations.diets"
                    :key="i"
                  >
                    <div @click="choose(option.id)" v-if="option">
                      {{ option.value }}
                    </div>
                  </div>
                </template>
              </Select>
            </div>

            <h2 class="form__header">Warsztaty</h2>
            <div class="workshops" v-if="informations">
              <Workshops
                :categories="informations.workshops_cat"
                :workshops="informations.workshops"
                @resultCategory="workshops.category = $event==null?null:$event"
                :selectedCategory="workshops.category"
                :categoryError="validation.firstError('workshops.category')"
                @resultWorkshop="workshops.workshop = $event==null?null:$event"
                :selectedWorkshop="workshops.workshop"
                :workshopError="validation.firstError('workshops.workshop')"
              />
            </div>

            <div class="tshirts" v-if="informations">
              <div class="form__row">
                <Tshirts @version="inputs.tshirt_version = $event" />
              </div>
              <div class="form__row" v-if="inputs.tshirt_version">
                <div class="form__row--col-2">
                  <Select
                    class="form__row--col-2"
                    v-if="informations"
                    label="Rodzaj"
                    :options="informations.tshirt_sex"
                    default=""
                    :getSelected="inputs.tshirt_sex"
                    :multiple="false"
                    @input="inputs.tshirt_sex = $event"
                  >
                    <template v-slot:display="{selectedElementstoDisplay}">
                      <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
                        <div v-for="item in selectedElementstoDisplay" :key="item.id">
                          {{ informations.tshirt_sex.find((e) => e.id == item).value }}
                        </div>
                      </div>
                      <div class="select__display" v-else></div>
                    </template>
                    <template v-slot:option="{choose, activateSelected}">
                      <div
                        class="select__item"
                        :class="{
                          'select__item--active': activateSelected(parseInt(option.id)),
                        }"
                        v-for="(option, i) of informations.tshirt_sex"
                        :key="i"
                      >
                        <div @click="choose(option.id)" v-if="option">
                          {{ option.value }}
                        </div>
                      </div>
                    </template>
                  </Select>
                </div>
                <div class="form__row--col-2">
                  <Select
                    class="form__row--col-2"
                    v-if="informations && inputs.tshirt_sex[0] == 1"
                    label="Rozmiarówka damska"
                    :options="informations.tshirt_size_women"
                    default=""
                    :getSelected="inputs.tshirt_size"
                    :multiple="false"
                    @input="inputs.tshirt_size = $event"
                  >
                    <template v-slot:display="{selectedElementstoDisplay}">
                      <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
                        <div v-for="item in selectedElementstoDisplay" :key="item.id">
                          {{ informations.tshirt_size_women.find((e) => e.id == item).value }}
                        </div>
                      </div>
                      <div class="select__display" v-else></div>
                    </template>
                    <template v-slot:option="{choose, activateSelected}">
                      <div
                        class="select__item"
                        :class="{
                          'select__item--active': activateSelected(parseInt(option.id)),
                        }"
                        v-for="(option, i) of informations.tshirt_size_women"
                        :key="i"
                      >
                        <div @click="choose(option.id)" v-if="option">
                          {{ option.value }}
                        </div>
                      </div>
                    </template>
                  </Select>
                  <Select
                    class="form__row--col-2"
                    v-if="informations && inputs.tshirt_sex[0] == 2"
                    label="Rozmiarówka męska"
                    :options="informations.tshirt_size_men"
                    default=""
                    :getSelected="inputs.tshirt_size"
                    :multiple="false"
                    @input="inputs.tshirt_size = $event"
                  >
                    <template v-slot:display="{selectedElementstoDisplay}">
                      <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
                        <div v-for="item in selectedElementstoDisplay" :key="item.id">
                          {{ informations.tshirt_size_men.find((e) => e.id == item).value }}
                        </div>
                      </div>
                      <div class="select__display" v-else></div>
                    </template>
                    <template v-slot:option="{choose, activateSelected}">
                      <div
                        class="select__item"
                        :class="{
                          'select__item--active': activateSelected(parseInt(option.id)),
                        }"
                        v-for="(option, i) of informations.tshirt_size_men"
                        :key="i"
                      >
                        <div @click="choose(option.id)" v-if="option">{{ option.value }}</div>
                      </div>
                    </template>
                  </Select>
                </div>
              </div>
            </div>

            <div v-if="informations != null && person.age != null && person.age < informations.parent_permit_age">
              <h2 class="form__header">Informacje dotyczące opiekuna</h2>
              <div class="form__row">
                <Input
                  class="form__row--col-2"
                  placeholder="Telefon opiekuna prawnego"
                  :value="guardian.guardian_phone"
                  :error="validation.firstError('guardian.guardian_phone')"
                  @return="guardian.guardian_phone = $event"
                />
                <Input
                  class="form__row--col-2"
                  placeholder="Imię i nazwisko opiekuna prawnego"
                  :value="guardian.guardian_name"
                  :error="validation.firstError('guardian.guardian_name')"
                  @return="guardian.guardian_name = $event"
                />
                <Input
                  v-if="person.age < informations.guardian_presence_age"
                  class="form__row--col-2"
                  placeholder="Numer ID opiekuna"
                  subtext="ID opiekuna uzyskasz od zarejestrowanej osoby pełnoletniej"
                  :value="guardian.guardian_id"
                  :error="validation.firstError('guardian.guardian_id')"
                  @return="guardian.guardian_id = $event"
                />
              </div>
            </div>

            <h2 class="form__header">Aby zaakceptować zgody - pobierz dokumenty</h2>
            <Checkbox
              number="3"
              text="Ogólne warunki uczestnictwa"
              :error="validation.firstError('accepts.terms')"
              download="/pdf/OGÓLNE-WARUNKI-UCZESTNICTWA-2022.pdf"
              @return="accepts.terms = $event"
            />
            <Checkbox
              number="3"
              text="Akceptuję regulamin wydarzenia"
              :error="validation.firstError('accepts.statute')"
              download="/pdf/REGULAMIN-WYDARZENIA-PARADISO-2022.pdf"
              @return="accepts.statute = $event"
            />
            <span class="form__price"> </span>
            <div class="form__btn" v-if="informations">
              <p>
                Koszt uczestnictwa: <span class="form__price">{{ informations.price }} PLN</span>
              </p>
              <p v-if="inputs.tshirt_version != '' && inputs.tshirt_sex != '' && inputs.tshirt_size != ''">
                Koszulka: <span class="form__price">+{{ informations.tshirt_price }} PLN</span>
              </p>
              <p v-if="inputs.tshirt_version != '' && inputs.tshirt_sex != '' && inputs.tshirt_size != ''">
                Razem: <span class="form__price">{{ informations.price + informations.tshirt_price }} PLN</span>
              </p>
              <br />
              <Btn v-on:click.native="submit()" text="Zarejestruj się" />
            </div>
          </div>

          <Loading v-if="loadingFlag" />
        </template>
      </PageText>
    </div>
  </transition>
</template>

<script>
  import axios from "axios";
  import {Validator} from "simple-vue-validator";

  import Input from "@/components/form/Input.vue";
  import Select from "@/components/form/Select.vue";
  import Checkbox from "@/components/form/Checkbox.vue";
  import Loading from "@/components/elements/Loading.vue";
  import Tshirts from "@/components/Registration/Tshirts.vue";
  import Workshops from "@/components/Registration/Workshops.vue";

  export default {
    name: "Registration",
    components: {
      Input,
      Select,
      Checkbox,
      Loading,
      Tshirts,
      Workshops,
    },
    data() {
      return {
        informations: null,
        loadingFlag: false,
        success: false,
        limitPeople: false,
        message: "",
        errors: [],
        person: {
          age: null,
        },
        inputs: {
          name: "",
          surname: "",
          pesel: "",
          passport: "",
          mail: "",
          datebirth: "",
          phone: "",
          diet_id: [],
          nationality: [],
          tshirt_version: "",
          tshirt_sex: "",
          tshirt_size: "",
        },
        accepts: {
          terms: "",
          statute: "",
        },
        guardian: {
          guardian_phone: "",
          guardian_name: "",
          guardian_id: "",
        },
        workshops: {
          category: [],
          workshop: [],
        },
      };
    },
    methods: {
      async getinfoAboutEvent() {
        const response = await axios.get(`/api/user/registration`);
        let tmpDiet = response.data.diets;
        // let tmpWorkshops = response.data.workshops;
        let tmpTshirtSex = response.data.tshirt_sex;
        let tmpTshirtSizeMen = response.data.tshirt_size_men;
        let tmpTshirtSizeWomen = response.data.tshirt_size_women;
        let tmpNationality = response.data.nationality;
        let tmpWorkshopshomogenic = response.data.workshops.homogenic;
        let tmpWorkshopsmix = response.data.workshops.mix;
        let tmpWorkshopsCat = response.data.workshops_cat;

        response.data.diets = [];
        // response.data.workshops = [];
        response.data.workshops.homogenic = [];
        response.data.workshops.mix = [];
        response.data.tshirt_sex = [];
        response.data.tshirt_size_men = [];
        response.data.tshirt_size_women = [];
        response.data.nationality = [];
        response.data.workshops_cat = [];
        for (const key in tmpDiet) {
          let obj = {id: key, value: tmpDiet[key]};
          response.data.diets.push(obj);
        }
        for (const key in tmpNationality) {
          let obj = {id: key, value: tmpNationality[key]};
          response.data.nationality.push(obj);
        }
        for (const key in tmpWorkshopsCat) {
          let obj = {id: key, value: tmpWorkshopsCat[key]};
          response.data.workshops_cat.push(obj);
        }
        for (const key in tmpTshirtSex) {
          let obj = {id: key, value: tmpTshirtSex[key]};
          response.data.tshirt_sex.push(obj);
        }
        for (const key in tmpTshirtSizeMen) {
          let obj = {id: key, value: tmpTshirtSizeMen[key]};
          response.data.tshirt_size_men.push(obj);
        }
        for (const key in tmpTshirtSizeWomen) {
          let obj = {id: key, value: tmpTshirtSizeWomen[key]};
          response.data.tshirt_size_women.push(obj);
        }
        for (const key in tmpWorkshopshomogenic) {
          let obj = {
            id: key,
            value: `Wariant ${key}: ${tmpWorkshopshomogenic[key]["content"]} (${tmpWorkshopshomogenic[key]["now"]}/${tmpWorkshopshomogenic[key]["limit"]} osób)`,
          };
          response.data.workshops.homogenic.push(obj);
        }
        for (const key in tmpWorkshopsmix) {
          let obj = {
            id: key,
            value: `Wariant ${key}: ${tmpWorkshopsmix[key]["content"]} (${tmpWorkshopsmix[key]["now"]}/${tmpWorkshopsmix[key]["limit"]} osób)`,
          };
          response.data.workshops.mix.push(obj);
        }
        this.informations = response.data;
      },
      async getInfoFromPesel(pesel) {
        this.errors = [];
        if (pesel.length == 11) {
          const response = await axios.post(`/api/user/pesel`, {pesel: pesel});
          if (response.data.age) {
            this.person.age = response.data.age;
          } else {
            this.errors.push(response.data.msg);
          }
        }
      },
      async getInfoDateBirth(datebirth) {
        this.errors = [];
        if (datebirth.length == 10) {
          const response = await axios.post(`/api/user/date-birth`, {date_birth: datebirth});
          if (response.data.age) {
            this.person.age = response.data.age;
          } else {
            this.errors.push(response.data.msg);
          }
        }
      },

      submit() {
        this.$validate().then(async (success) => {
          if (success) {
            const object = {...this.inputs, ...this.guardian, ...this.person};
            if (object.tshirt_version == 0) {
              object.tshirt_version = "";
              object.tshirt_sex = "";
              object.tshirt_size = "";
            } else {
              object.tshirt_sex = object.tshirt_sex[0];
              object.tshirt_size = object.tshirt_size[0];
            }
            object.workshop = this.workshops.workshop[0];
            object.nationality = object.nationality[0];
            this.loadingFlag = true;
            const response = await axios.post(`/api/user/create`, object);
            if (response.data.errors) {
              this.errors = response.data.errors;
              this.loadingFlag = false;
            }
            if (response.data.success) {
              this.loadingFlag = false;
              this.success = true;
            }
          }
        });
      },
    },
    watch: {
      inputs: {
        deep: true,
        handler() {
          // if (this.errors.length > 0) {
          //       this.errors = []
          //     }
          this.message = "";
        },
      },
    },
    mounted() {
      this.getinfoAboutEvent();
    },
    validators: {
      "inputs.name": function(value) {
        return Validator.value(value)
          .required("Pole wymagane")
          .minLength(3, "Wprowadź minimum 3 znaki");
      },
      "inputs.surname": function(value) {
        return Validator.value(value)
          .required("Pole wymagane")
          .minLength(3, "Wprowadź minimum 3 znaki");
      },
      "inputs.pesel": function(value) {
        if (this.inputs.nationality == 1) {
          return Validator.value(value)
            .required("Pole wymagane")
            .digit("Wprawadź tylko cyfry")
            .length(11, "PESEL musi mieć 11 znaków");
        }
      },
      "inputs.mail": function(value) {
        return Validator.value(value)
          .required("Pole wymagane")
          .email("Wprowadź prawidłowy adres E-mail");
      },
      "inputs.phone": function(value) {
        return Validator.value(value)
          .required("Pole wymagane")
          .digit("Wprawadź tylko cyfry")
          .length(9, "Numer musi mieć 9 znaków");
      },
      // "inputs.workshop": function(value) {
      //   return Validator.value(value).required("Pole wymagane");
      // },
      "inputs.nationality": function(value) {
        return Validator.value(value).required("Pole wymagane");
      },
      "inputs.passport": function(value) {
        if (this.inputs.nationality == 2) {
          return Validator.value(value).required("Pole wymagane");
        }
      },
      "inputs.datebirth": function(value) {
        if (this.inputs.nationality == 2) {
          return Validator.value(value)
            .required("Pole wymagane")
            .length(10, "Data musi mieć 8 znaków");
        }
      },
      "guardian.guardian_phone": function(value) {
        if (this.person.age < this.informations.parent_permit_age) {
          return Validator.value(value)
            .required("Pole wymagane")
            .digit("Wprawadź tylko cyfry")
            .length(9, "Numer musi mieć 9 znaków");
        }
      },
      "guardian.guardian_name": function(value) {
        if (this.person.age < this.informations.parent_permit_age) {
          return Validator.value(value)
            .required("Pole wymagane")
            .minLength(3, "Wprowadź minimum 3 znaki");
        }
      },
      "guardian.guardian_id": function(value) {
        if (this.person.age < this.informations.guardian_presence_age) {
          return Validator.value(value)
            .required("Pole wymagane")
            .digit("Wprawadź tylko cyfry");
        }
      },
      "workshops.category": function(value) {
        return Validator.value(value).required("Pole wymagane");
      },
      "workshops.workshop": function(value) {
        if (this.workshops.category.length > 0) {
          return Validator.value(value).required("Pole wymagane");
        }
      },
      "accepts.terms": function(value) {
        return Validator.value(value).required("Pole wymagane");
      },
      "accepts.statute": function(value) {
        return Validator.value(value).required("Pole wymagane");
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form {
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      &--col-2 {
        width: 100%;
        @media (min-width: 900px) {
          width: 48%;
        }
      }
    }
    &__header {
      text-transform: uppercase;
      margin-bottom: 1rem;
      &--red {
        color: $red;
      }
    }
    &__item {
      // width: 100%;
      position: relative;
    }
    &__btn {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
    &__success {
      font-size: 2rem;
      line-height: 2.5rem;
    }
    &__error {
      color: $red;
      margin-bottom: 0.5rem;
      font-weight: 700;
    }
    &__price {
      color: $red;
    }
  }
</style>
