<template>
  <div class="worksop">
    <div class="form__row">
      <Select
        class="form__row--col-2"
        label="Typ warsztatu"
        :options="categories"
        default=""
        :getSelected="selectedCategory"
        :multiple="false"
        @input="resultCategory($event)"
        :error="categoryError"
      >
        <template v-slot:display="{selectedElementstoDisplay}">
          <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
            <div v-for="item in selectedElementstoDisplay" :key="item.id">
              {{ categories.find((e) => e.id == item).value }}
            </div>
          </div>
          <div class="select__display" v-else></div>
        </template>
        <template v-slot:option="{choose, activateSelected}">
          <div
            class="select__item"
            :class="{
              'select__item--active': activateSelected(parseInt(option.id)),
            }"
            v-for="(option, i) of categories"
            :key="i"
          >
            <div @click="choose(option.id)" v-if="option">
              {{ option.value }}
            </div>
          </div>
        </template>
      </Select>

      <Select
        v-if="selectedCategory[0] == 1"
        class="form__row--col-2"
        label="Warsztat"
        :options="workshops.homogenic"
        default=""
        :getSelected="selectedWorkshop"
        :multiple="false"
        @input="resultWorkshop($event)"
        :error="workshopError"
      >
        <template v-slot:display="{selectedElementstoDisplay}">
          <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
            <div v-for="item in selectedElementstoDisplay" :key="item.id">
              {{ workshops.homogenic.find((e) => e.id == item).value }}
            </div>
          </div>
          <div class="select__display" v-else></div>
        </template>
        <template v-slot:option="{choose, activateSelected}">
          <div
            class="select__item"
            :class="{
              'select__item--active': activateSelected(parseInt(option.id)),
            }"
            v-for="(option, i) of workshops.homogenic"
            :key="i"
          >
            <div @click="choose(option.id)" v-if="option">
              {{ option.value }}
            </div>
          </div>
        </template>
      </Select>

      <Select
        v-if="selectedCategory[0] == 2"
        class="form__row--col-2"
        label="Warsztat"
        :options="workshops.mix"
        default=""
        :getSelected="selectedWorkshop"
        :multiple="false"
        @input="resultWorkshop($event)"
        :error="workshopError"
      >
        <template v-slot:display="{selectedElementstoDisplay}">
          <div class="select__display" v-if="Array.isArray(selectedElementstoDisplay)">
            <div v-for="item in selectedElementstoDisplay" :key="item.id">
              {{ workshops.mix.find((e) => e.id == item).value }}
            </div>
          </div>
          <div class="select__display" v-else></div>
        </template>
        <template v-slot:option="{choose, activateSelected}">
          <div
            class="select__item"
            :class="{
              'select__item--active': activateSelected(parseInt(option.id)),
            }"
            v-for="(option, i) of workshops.mix"
            :key="i"
          >
            <div @click="choose(option.id)" v-if="option">
              {{ option.value }}
            </div>
          </div>
        </template>
      </Select>
    </div>
  </div>
</template>

<script>
  import Select from "@/components/form/Select.vue";
  export default {
    components: {
      Select,
    },
    data() {
      return {
        version: 0,
      };
    },
    props: {
      selectedWorkshop: Array,
      selectedCategory: Array,
      categoryError: String,
      workshopError: String,
      categories: Array,
      workshops: Object,
    },
    methods: {
      resultCategory(event) {
        this.$emit("resultWorkshop", null);
        this.$emit("resultCategory", event);
      },
      resultWorkshop(event) {
        this.$emit("resultWorkshop", event);
      },
    },
  };
</script>
<style lang="scss" scoped>
  .form {
    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      &--col-2 {
        width: 100%;
        @media (min-width: 900px) {
          width: 48%;
        }
      }
    }
  }
</style>
