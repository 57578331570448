<template>
  <div class="tshirts">
    <div class="pagination">
      <div class="pagination__title">Koszulka (wybierz wersję koszulki)</div>
      <div class="pagination__row">
        <p class="pagination__item" :class="{'pagination__item--active': version == 0}" v-on:click="select(0)">
          Brak koszulki
        </p>
        <p class="pagination__item" :class="{'pagination__item--active': version == 1}" v-on:click="select(1)">
          Wersja 1
        </p>
        <p class="pagination__item" :class="{'pagination__item--active': version == 2}" v-on:click="select(2)">
          Wersja 2
        </p>
        <p class="pagination__item" :class="{'pagination__item--active': version == 3}" v-on:click="select(3)">
          Wersja 3
        </p>
        <p class="pagination__item" :class="{'pagination__item--active': version == 4}" v-on:click="select(4)">
          Wersja 4
        </p>
      </div>
      <div class="pagination__row"> 
        <a v-html="$func.hoverWord('POBIERZ ROZMIARÓWKĘ')" href="/pdf/Rozmiary_koszulek_PARADISO_2022.pdf" download> </a>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <img src="@/assets/tshirt/Koszulki_1.jpg" alt="" v-bind:key="1" v-if="version == 1" />
      <img src="@/assets/tshirt/Koszulki_1_z_napisem.jpg" alt="" v-bind:key="2" v-if="version == 2" />
      <img src="@/assets/tshirt/Koszulki_2.jpg" alt="" v-bind:key="3" v-if="version == 3" />
      <img src="@/assets/tshirt/Koszulki_2_z_napisem.jpg" alt="" v-bind:key="4" v-if="version == 4" />
    </transition>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        version: 0,
      };
    },
    props: {
      text: String,
      reverse: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      select(version) {
        this.version = version;
        this.$emit("version", version);
      },
    },
  };
</script>
<style scoped lang="scss">
  .tshirts {
    width: 100%;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>
