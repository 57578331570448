var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"worksop"},[_c('div',{staticClass:"form__row"},[_c('Select',{staticClass:"form__row--col-2",attrs:{"label":"Typ warsztatu","options":_vm.categories,"default":"","getSelected":_vm.selectedCategory,"multiple":false,"error":_vm.categoryError},on:{"input":function($event){return _vm.resultCategory($event)}},scopedSlots:_vm._u([{key:"display",fn:function(ref){
var selectedElementstoDisplay = ref.selectedElementstoDisplay;
return [(Array.isArray(selectedElementstoDisplay))?_c('div',{staticClass:"select__display"},_vm._l((selectedElementstoDisplay),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(_vm.categories.find(function (e) { return e.id == item; }).value)+" ")])}),0):_c('div',{staticClass:"select__display"})]}},{key:"option",fn:function(ref){
var choose = ref.choose;
var activateSelected = ref.activateSelected;
return _vm._l((_vm.categories),function(option,i){return _c('div',{key:i,staticClass:"select__item",class:{
            'select__item--active': activateSelected(parseInt(option.id)),
          }},[(option)?_c('div',{on:{"click":function($event){return choose(option.id)}}},[_vm._v(" "+_vm._s(option.value)+" ")]):_vm._e()])})}}])}),(_vm.selectedCategory[0] == 1)?_c('Select',{staticClass:"form__row--col-2",attrs:{"label":"Warsztat","options":_vm.workshops.homogenic,"default":"","getSelected":_vm.selectedWorkshop,"multiple":false,"error":_vm.workshopError},on:{"input":function($event){return _vm.resultWorkshop($event)}},scopedSlots:_vm._u([{key:"display",fn:function(ref){
          var selectedElementstoDisplay = ref.selectedElementstoDisplay;
return [(Array.isArray(selectedElementstoDisplay))?_c('div',{staticClass:"select__display"},_vm._l((selectedElementstoDisplay),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(_vm.workshops.homogenic.find(function (e) { return e.id == item; }).value)+" ")])}),0):_c('div',{staticClass:"select__display"})]}},{key:"option",fn:function(ref){
          var choose = ref.choose;
          var activateSelected = ref.activateSelected;
return _vm._l((_vm.workshops.homogenic),function(option,i){return _c('div',{key:i,staticClass:"select__item",class:{
            'select__item--active': activateSelected(parseInt(option.id)),
          }},[(option)?_c('div',{on:{"click":function($event){return choose(option.id)}}},[_vm._v(" "+_vm._s(option.value)+" ")]):_vm._e()])})}}],null,false,44834104)}):_vm._e(),(_vm.selectedCategory[0] == 2)?_c('Select',{staticClass:"form__row--col-2",attrs:{"label":"Warsztat","options":_vm.workshops.mix,"default":"","getSelected":_vm.selectedWorkshop,"multiple":false,"error":_vm.workshopError},on:{"input":function($event){return _vm.resultWorkshop($event)}},scopedSlots:_vm._u([{key:"display",fn:function(ref){
          var selectedElementstoDisplay = ref.selectedElementstoDisplay;
return [(Array.isArray(selectedElementstoDisplay))?_c('div',{staticClass:"select__display"},_vm._l((selectedElementstoDisplay),function(item){return _c('div',{key:item.id},[_vm._v(" "+_vm._s(_vm.workshops.mix.find(function (e) { return e.id == item; }).value)+" ")])}),0):_c('div',{staticClass:"select__display"})]}},{key:"option",fn:function(ref){
          var choose = ref.choose;
          var activateSelected = ref.activateSelected;
return _vm._l((_vm.workshops.mix),function(option,i){return _c('div',{key:i,staticClass:"select__item",class:{
            'select__item--active': activateSelected(parseInt(option.id)),
          }},[(option)?_c('div',{on:{"click":function($event){return choose(option.id)}}},[_vm._v(" "+_vm._s(option.value)+" ")]):_vm._e()])})}}],null,false,513404824)}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }